import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">

          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top" style={{
            padding: '10px 15px' // 로고 주변 패딩 줄이기
          }}>
            <img
              src="/img/data101logo.png"
              alt="DATA101"
              style={{
                height: '40px', // 로고 높이 증가 (30px에서 40px로)
                marginTop: '-5px', // 위치 조정
                display: 'inline-block'
              }}
            />
          </a>{" "}
          {/* <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            DATA101
          </a>{" "} */}


        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">

            <li>
              <a href="#services" className="page-scroll">
                서비스
              </a>
            </li>

            <li>
              <a href="#use" className="page-scroll">
                체험하기
              </a>
            </li>

            <li>
              <a href="#blog" className="page-scroll">
                블로그
              </a>
            </li>

            <li>
              <a href="#team" className="page-scroll">
                팀소개
              </a>
            </li>

            {/* <li>
              <a href="#customer" className="page-scroll">
                고객리뷰
              </a>
            </li> */}

            <li>
              <a href="#contact" className="page-scroll">
                CONTACT US
              </a>
            </li>

            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> */}

          </ul>
        </div>
      </div>
    </nav>
  );
};
