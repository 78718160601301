import React from "react";

export const Services = (props) => {
  // \n을 <br />로 변환하는 함수
  const formatTextWithLineBreaks = (text) => {
    return text ? text.replace(/\n/g, "<br />") : "loading...";
  };

  // Why 배열을 두 부분으로 나누기
  const getWhyItems = (start, end) => {
    if (!props.data || !props.data.Why) return "loading";
    return props.data.Why.slice(start, end).map((d, i) => (
      <li key={`${d}-${i}`}>{d}</li>
    ));
  };

  return (
    <div id="services">
      <div className="container">
        <br />
        {/* 첫 번째 섹션: 좌측 사진 - 우측 설명 */}
        <div className="row" style={{ marginBottom: "60px" }}>

          <div className="col-xs-12 col-md-6 d-flex justify-content-center">
            {" "}
            <div style={{
              width: "100%", /* 컬럼 너비에 맞춤 */
              maxWidth: "400px", /* 최대 너비 제한 */
              aspectRatio: "2/3", /* 비율 유지 */
              borderRadius: "15px",
              overflow: "hidden"
            }}>
              <br/>
              <video
                autoPlay
                muted
                loop
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  display: "block"
                }}>
                <source src="/videos/highlightai.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="services-text">
              <br/>
              <h2>{props.data ? props.data.title1 : "일일이 원문을 읽어보고 계신가요?"}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.data
                    ? formatTextWithLineBreaks(props.data.paragraph)
                    : "loading...",
                }}
              />

              <h3> 속도</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul style={{ fontSize: "16px" }}>
                    {getWhyItems(0, 2)}
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {getWhyItems(3, 5)}
                  </ul>
                </div> */}
              </div>

              <br /><br /><br /><br />
              <h3>정확도</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul style={{ fontSize: "16px" }}>
                    {getWhyItems(2, 4)}
                  </ul>
                </div>
              </div>

              <br /><br /><br /><br />
              <h3>비용</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul style={{ fontSize: "16px" }}>
                    {getWhyItems(4, 6)}
                  </ul>
                </div>
              </div>

              <br /><br /><br /><br />
              <h3>처리량</h3>
              <div className="list-style">
                <div className="col-xs-12">
                  <ul style={{ fontSize: "16px" }}>
                    {getWhyItems(6, 8)}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};