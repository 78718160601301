import React from "react";

export const Use = (props) => {
  return (
    <div id="use" className="text-center">
      <div className="container">
        <div className="section-title">
          <br/>
          <h2>DATA101 체험하기</h2>
          <p>
            아래 페이지에서 DATA101의 데이터 요약 서비스를 체험할 수 있습니다.
          </p>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4"> //한 줄에 세 개 나타낼수있는거
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-12 text-center">
                  <a href={"https://beta.data101.io"} target="_blank" rel="noopener noreferrer">
                    <img src="/img/favicon_shadow.png" alt={d.title} width="100" height="100" />
                  </a>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
