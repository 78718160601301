import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Blog } from "./components/blog";
import { Services } from "./components/services";
import { Use } from "./components/use";
import { Gallery } from "./components/gallery";
import { Customer } from "./components/customer";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Helmet>
        <title>DATA101-데이터분석의 모든것</title>
        <meta name="description" content="데이터 전처리 및 분석을 위한 종합 플랫폼" /> 
        <meta name="keywords" content="데이터 분석, 데이터 전처리, 데이터 시각화, AI 알고리즘" />
      </Helmet>
      
      <Navigation />
      <Header data={landingPageData.Header} />
      <Services data={landingPageData.Services} />
      <Use data={landingPageData.Use} />
      <Blog data={landingPageData.Blog} />
      <Team data={landingPageData.Team} />
      {/* <Customer data={landingPageData.Customer} /> */}
      <Contact data={landingPageData.Contact} />
      {/* <Gallery data={landingPageData.Gallery} /> */}
    </div>
  );
};

export default App;