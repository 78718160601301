import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { SiKakaotalk } from 'react-icons/si';
import { FaInstagram, FaYoutube } from "react-icons/fa"; // ✅ 인스타그램, 유튜브 아이콘 추가
import Swal from "sweetalert2";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("service_7kcfehq", "template_ytpzm9c", e.target, "pT4gkEjKiFAk3Z_2E")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          Swal.fire({
            icon: "success", // 성공 아이콘
            title: "성공!", // 팝업 제목
            text: "성공적으로 전송되었습니다.\n빠른 시일내에 영업팀에서 연락 드리겠습니다.", // 팝업 내용
            confirmButtonText: "확인", // 버튼 텍스트
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error", // 실패 아이콘
            title: "실패!", // 팝업 제목
            text: "메시지 전송에 실패했습니다. 다시 시도해주세요.", // 팝업 내용
            confirmButtonText: "확인", // 버튼 텍스트
          });
        }
      );
  };

  const iconStyle = (bgColor) => ({
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: bgColor,
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // 🔹 그림자 효과 추가
  });

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">

              <div className="section-title">
                <h2>CONTACT US</h2>
                <p>
                  DATA101 서비스에 대한 이용/비용 문의는 아래 폼으로 신청 바랍니다.
                </p>

              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
              <ul style={{ display: "flex", justifyContent: "center", alignItems:"center", gap: "15px", listStyle: "none", padding: 0 }}>
              <li style={iconStyle("#fee500")}>
                <a href={props.data ? props.data.kakao : "/"}>
                  <SiKakaotalk size={24} color="#000" />
                </a>
              </li>
              <li style={iconStyle("#E4405F")}>
                <a href={props.data ? props.data.instagram : "/"}>
                  <FaInstagram size={24} color="#fff" />
                </a>
              </li>
              <li style={iconStyle("#FF0000")}>
                <a href={props.data ? props.data.youtube : "/"}>
                  <FaYoutube size={24} color="#fff" />
                </a>
              </li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
          대표이사 : 박경빈 | 이메일 : gbpark@uf5.io | 전화번호 : 0507-1334-3546 
          </p>         
          <p>
          사업자등록번호: 376-87-03088 | 통신판매신고번호 : 제2024-서울강남-03158호
          </p>          
          <p>
          서울특별시 강남구 남부순환로 359길 14,3F
          </p>
          <p>
              {" "}
              <a href="https://lateral-chicken-afd.notion.site/5-12e24331e1be809e8467dd135b4eac9a?pvs=4" rel="nofollow">
                서비스 이용약관 | 
              </a>
              {" "}
              <a href="https://lateral-chicken-afd.notion.site/5-12e24331e1be8086b79ae8b727faebf1" rel="nofollow">
                개인정보 취급방침
              </a>
          </p>
          <p>

          </p>
          <p>
          Copyright © 2025 UF5. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
