import React from "react";

export const Blog = (props) => {
  return (
    <div id="blog" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <br /><br /><br /><br/><br/><br/>
          <h2>BLOG</h2>
          <p>
            DATA101 의 블로그에서 최신 데이터 분석 동향 및 실제 사례를 확인하세요
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
              <a href={d.link} target="_blank" rel="noopener noreferrer">
                <img src={d.icon} alt={d.title} width="100" height="100" style={{ borderRadius: '15px' }} />
              </a>
                {/* <i className={d.icon}></i> */}
                <h3>{d.title}</h3>
                <p>{d.text}</p>
              </div>
            ))
            : "Loading..."}
        </div>
        <br/><br/><br/><br/><br/><br/><br/><br/>
      </div>
    </div>
  );
};
