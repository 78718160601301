import React from "react";
export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title" style={{ marginBottom: '50px' }}>
          <h2>팀 소개</h2><br />
          <p>UF5는 텍스트 전용 데이터 분석을 전문으로 하는 B2B SaaS 기업입니다.</p><br />
          <p>다년간의 SI 프로젝트에서 축적된 기술력을 바탕으로 2023년 DATA101 서비스를 시작했습니다.</p><br />
          <p>SaaS 서비스로 도입이 쉽고 누구나 현업에서 바로 활용할 수 있도록 설계되었으며</p><br/>
          <p>각 산업의 특성을 고려한 맞춤형 모델을 지원하여 실질적인 비즈니스 가치를 제공합니다.</p>
        </div>

        {/* 첫 번째 이미지 섹션 */}
        <div id="row">
          {props.data && props.data[0] && (
            <div className="col-12 team">
              <div className="thumbnail">
                <img
                  src={props.data[0].img}
                  alt="..."
                  className="team-img"
                  style={{ width: '100%', maxWidth: '800px', height: 'auto', display: 'block', margin: '0 auto' }}
                />
                <div className="caption">
                  <h4>{props.data[0].name}</h4>
                  <p>{props.data[0].job}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* <div className="col-md-8 col-md-offset-2 section-title" style={{ marginBottom: '0px' }}>
          <p>SaaS 서비스로 도입이 쉽고 누구나 현업에서 바로 활용할 수 있도록 설계되었으며</p><br/>
          <p>각 산업의 특성을 고려한 맞춤형 모델을 지원하여 실질적인 비즈니스 가치를 제공합니다.</p>
        </div> */}
        {/* 두 번째 이미지 섹션 */}
        {/* <div id="row">
          {props.data && props.data[1] && (
            <div className="col-12 team">
              <div className="thumbnail">
                <img
                  src={props.data[1].img}
                  alt="..."
                  className="team-img"
                  style={{ width: '100%', maxWidth: '800px', height: 'auto', display: 'block', margin: '0 auto' }}
                />
                <div className="caption">
                  <h4>{props.data[1].name}</h4>
                  <p>{props.data[1].job}</p>
                </div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};